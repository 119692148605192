import React from "react"
import styled from "styled-components"

import { respTextFont, scrollBarStyles } from "../../utils/sharedStyles"

const StyledWrapper = styled.div`
  ${respTextFont}
  ${scrollBarStyles}

  width: 100%;
  min-height: 80px;
  border: ${({ theme }) => `2px solid ${theme.colors.interactiveBorder}`};
  background: transparent;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
  padding: 14px;
  border-radius: 7px;

  ${({ theme }) => theme.media.tablet} {
    padding: 10px;
    border-radius: 7px;
  }

  ${({ theme }) => theme.media.phone} {
    padding: 8px;
    border-radius: 6px;
  }
`

const InteractiveContainer = props => (
  <StyledWrapper {...props}>{props.children}</StyledWrapper>
)

export default InteractiveContainer
