import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ContactCard from "./ContactCard"

import { contactsData } from "./secPanelTypes"

const contactData: contactsData = [
  {
    header:
      "In case of business matters - feel free to contact our Business Development Team:",
    name: "Tomasz Bogus",
    photoName: "tomasz",
    title: "Business Developer",
    email: "tomasz.bogus@scalac.io",
    phone1: "+48 602 208 469",
    phone2: "+1 646 583 1260",
  }
]
const SecPanel = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)/" }
          relativeDirectory: { eq: "photos" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `)

  return (
    <>
      {contactData.map(contact =>
        data.allFile.edges.map(({ node }, index) => (
          node.name ===  contact.photoName &&
          <ContactCard
            key={index}
            photo={node.childImageSharp.fluid}
            contact={contact}
          />
        )))
      }
    </>
  )
}

export default SecPanel
