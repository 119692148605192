import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/shared/Seo"
import PanelContainer from "../components/shared/PanelContainer"
import FirstPanel from "../components/Contact/FirstPanel/FirstPanel"
import SecPanel from "../components/Contact/SecPanel/SecPanel"

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <PanelContainer>
        <FirstPanel />
      </PanelContainer>
      <PanelContainer>
        <SecPanel />
      </PanelContainer>
    </Layout>
  )
}

export default Contact
