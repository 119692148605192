import React from "react"
import styled, { css } from "styled-components"
import Img, { FluidObject } from "gatsby-image"

import InteractiveContainer from "../../../components/shared/InteractiveContainer"
import {
  respTextFont,
  respH4Font,
  respH2Font,
  respH3Font,
} from "../../../utils/sharedStyles"
import EmailIcon from "../../../utils/assets/EmailIcon"
import { contactData } from "./secPanelTypes"

const ContactCard = ({
  contact,
  photo,
}: {
  contact: contactData
  photo: FluidObject
}) => {
  const { header, name, title, email, phone1, phone2 } = contact

  return (
    <>
      <StyledHeader>{header}</StyledHeader>
      <ContactContainer data-cy="card-contact-page">
        <PhotoWrapper>
          {/* @ts-ignore Gatsby-image require direct styling */}
          <StyledPhoto fluid={photo} alt={name} />
        </PhotoWrapper>
        <DataWrapper>
          <TitleWrapper>
            <NameContainer>{name}</NameContainer>
            <TitleContainer>{title}</TitleContainer>
          </TitleWrapper>
          <PhoneWrapper>
            <StyledDiv>{phone1}</StyledDiv>
            <StyledDiv>{phone2}</StyledDiv>
          </PhoneWrapper>
          <EmailWrapper>
            <StyledEmailIcon />
            <Email
              href={`https://mail.google.com/mail/?view=cm&source=mailto&to=[${email}]`}
            >
              {email}
            </Email>
          </EmailWrapper>
        </DataWrapper>
      </ContactContainer>
    </>
  )
}

const StyledHeader = styled.h3`
  ${respH3Font}
  margin: 10px 0;
  text-align: left;
`

const ContactContainer = styled(InteractiveContainer)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  margin: 10px;
`

const PhotoWrapper = styled.div`
  display: flex;
  width: 200px;
  overflow: hidden;
  justify-content: center;
`

const StyledPhoto = styled(Img)`
  margin: 0;
  max-height: 210px;
  width: 100%;
  display: flex;

  ${({ theme }) => theme.media.tablet} {
    max-height: 180px;
  }

  ${({ theme }) => theme.media.phone} {
    max-height: 200px;
  }
`

const DataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1.2rem;
  padding: 2.5rem;
  max-height: 210px;

  ${({ theme }) => theme.media.tablet} {
    padding: 0.6rem;
    max-height: 180px;
  }

  ${({ theme }) => theme.media.phone} {
    padding: 1rem;
    max-height: 200px;
  }
`

const sharedStyles = css`
  width: 50%;
  min-width: 12rem;
`

const TitleWrapper = styled.div`
  ${sharedStyles}
`

const NameContainer = styled.div`
  ${respH4Font}
`

const TitleContainer = styled.div`
  ${respH2Font}
  font-size: 1.1rem;

  ${({ theme }) => theme.media.tablet} {
    font-size: 0.9rem;
  }

  ${({ theme }) => theme.media.phone} {
    font-size: 0.8rem;
  }
`

const StyledDiv = styled.div`
  ${respTextFont}
  padding: 3px 0;
`

const PhoneWrapper = styled.div`
  ${sharedStyles}
`

const EmailWrapper = styled.div`
  ${sharedStyles}
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledEmailIcon = styled(EmailIcon)`
  fill: ${({ theme }) => theme.colors.primary};
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
  height: 1rem;
  ${({ theme }) => theme.media.phone} {
    min-width: 0.9rem;
    min-height: 0.9rem;
  }
`

const Email = styled.a`
  ${respTextFont}
  color: ${({ theme }) => theme.colors.primary};
  padding-left: 0.5rem;
`

export default ContactCard
