import React from "react"
import styled, { css } from "styled-components"

import {
  respH1Font,
  respH2Font,
  respH3Font,
  respTextFont,
} from "../../../utils/sharedStyles"
import GlobeIcon from "../../../utils/assets/GlobeIcon"

const sharedStyles = css`
  margin: 10px 0;
  text-align: left;
`

const StyledH1 = styled.h1`
  ${sharedStyles}
  ${respH1Font}
`

const StyledH2 = styled.h2`
  ${sharedStyles}
  ${respH2Font}
`

const AddressWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 30px 0;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
  }
`

const StyledTitle = styled.div`
  ${respH2Font}
  display: flex;
  margin-top: 5rem;
  align-items: center;

  ${({ theme }) => theme.media.tablet} {
    margin-top: 4rem;
  }

  ${({ theme }) => theme.media.phone} {
    margin-top: 3rem;
  }
`

const StyledGlobeIcon = styled(GlobeIcon)`
  fill: white;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 20px;
`

const AddressContainer = styled.div`
  margin-right: 10%;
`

const StyledLocation = styled.div`
  ${respH3Font}
  margin-top: 1rem;
`

const StyledText = styled.div`
  ${respTextFont}
  opacity: 0.6;
  margin: 0.5rem 0;
`

const FirstPanel = () => {
  return (
    <>
      <StyledH1>Contact</StyledH1>
      <StyledH2>
        Let us help you to find and solve the issues by transforming your
        user-generated content into deep, actionable insights.
      </StyledH2>
      <StyledTitle>
        <StyledGlobeIcon />
        Headquarters
      </StyledTitle>
      <AddressWrapper data-cy="headquarters-contact-page">
        <AddressContainer>
          <StyledLocation>Gdansk - Poland</StyledLocation>
          <StyledText>Scalac Sp. z o. o.</StyledText>
          <StyledText>Czesława Miłosza 9/9</StyledText>
          <StyledText>80-126 Gdańsk, Polska</StyledText>
          <StyledText>+48 730 030 492</StyledText>
          <StyledText>KRS 0000493511</StyledText>
          <StyledText>NIP 5842734834</StyledText>
        </AddressContainer>
        <AddressContainer>
          <StyledLocation>San Francisco - USA</StyledLocation>
          <StyledText>Scalac, Inc.</StyledText>
          <StyledText>1160 Battery St Suites 100</StyledText>
          <StyledText>San Francisco, CA 94111, USA</StyledText>
        </AddressContainer>
      </AddressWrapper>
    </>
  )
}

export default FirstPanel
